























































import Vue from 'vue';
import moment from 'moment';
import ProfileTextFieldModal from '@/views/profile/partials/profile-text-field-modal.vue';
import { IComment } from '@/scripts/store/modules/comments/types';

export default Vue.extend({
  name: 'profile-header',
  components: {
    ProfileTextFieldModal,
  },
  data() {
    return {
      editing: false,
      loading: false,
    };
  },
  computed: {
    commentStatuses(): Array<IComment> {
      return this.$store.getters['comments/getCommentsStatuses'];
    },
    user(): any {
      return this.$store.getters.user;
    },
    joinedIn(): string | undefined {
      if (!this.user.registrationEvent) return;
      return `${this.$t('app.profile.account.joined')} ${moment(this.user.registrationEvent).format('LL')}`;
    },
  },
  methods: {
    onEditFirstName() {
      this.$log.debug('Editing firstname');
      this.editing = true;
    },
    onUpdateFirstName(newValue: string) {
      this.$log.debug('Update first name with value', newValue);
      if (newValue && newValue.length) {
        this.editing = false;
        this.loading = true;
        this.$store.dispatch('editUser', { firstName: newValue }).then(() => {
          this.$store.commit('setNotification', { text: this.$t('app.profile.notifications.updated') });
        }).finally(() => {
          this.loading = false;
        });
      }
    },
  },
});
