var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "radius-15 pa-2", attrs: { elevation: "3" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c("v-flex", [
            _c("h3", { staticClass: "starling-h3" }, [
              _vm._v(_vm._s(_vm.$t("app.profile.account.title")))
            ])
          ]),
          _c(
            "v-flex",
            { attrs: { xs12: "", "mt-1": "" } },
            [_c("v-divider")],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "", "align-center": "", "mt-2": "" } },
        [
          _c("v-flex", { attrs: { xs12: "", md4: "", "pr-3": "" } }, [
            _c("p", { staticClass: "starling-text text--disabled" }, [
              _vm._v(
                _vm._s(_vm.$t("app.profile.account.fields.organization.title"))
              )
            ])
          ]),
          _c("v-flex", { attrs: { xs12: "", md8: "" } }, [
            _c("p", { staticClass: "starling-text font-weight-bold" }, [
              _vm._v(_vm._s(_vm.user.organization))
            ])
          ])
        ],
        1
      ),
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "", "align-center": "", "mt-2": "" } },
        [
          _c("v-flex", { attrs: { xs12: "", md4: "", "pr-3": "" } }, [
            _c("p", { staticClass: "starling-text text--disabled" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.businessUnitConfigLabel
                      ? _vm.businessUnitConfigLabel
                      : _vm.$t("app.profile.account.fields.businessUnit.title")
                  ) +
                  "\n      "
              )
            ])
          ]),
          _c("v-flex", { attrs: { xs12: "", md8: "" } }, [
            _c("p", { staticClass: "starling-text font-weight-bold" }, [
              _vm._v(
                _vm._s(
                  _vm.user.businessUnitOther
                    ? _vm.user.businessUnitOther
                    : _vm.businessUnitLabel(_vm.user.businessUnitId)
                )
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "", "align-center": "", "mt-2": "" } },
        [
          _c("v-flex", { attrs: { xs12: "", md4: "", "pr-3": "" } }, [
            _c("p", { staticClass: "starling-text text--disabled" }, [
              _vm._v(_vm._s(_vm.$t("app.profile.account.fields.email.title")))
            ])
          ]),
          _c("v-flex", { attrs: { xs12: "", md8: "" } }, [
            _c(
              "p",
              { staticClass: "starling-text font-weight-bold ellipsis" },
              [_vm._v(_vm._s(_vm.user.email))]
            )
          ])
        ],
        1
      ),
      _vm.memberIdConfig && _vm.memberIdConfig.active
        ? _c(
            "v-layout",
            { attrs: { row: "", wrap: "", "align-center": "", "mt-2": "" } },
            [
              _c("v-flex", { attrs: { xs12: "", md4: "", "pr-3": "" } }, [
                _c("p", { staticClass: "starling-text text--disabled" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("app.profile.account.fields.memberCode.title")
                    )
                  )
                ])
              ]),
              _c("v-flex", { attrs: { xs12: "", md8: "" } }, [
                _c("p", { staticClass: "starling-text font-weight-bold" }, [
                  _vm._v(_vm._s(_vm.user.organizationMemberCode))
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "", "align-center": "", "mt-2": "" } },
        [
          _c("v-flex", { attrs: { xs11: "", md4: "", "pr-3": "" } }, [
            _c("p", { staticClass: "starling-text text--disabled" }, [
              _vm._v(
                _vm._s(_vm.$t("app.profile.account.fields.password.title"))
              )
            ])
          ]),
          _c(
            "v-flex",
            { attrs: { xs1: "", "order-md2": "" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "text--disabled ma-0",
                  attrs: {
                    small: "",
                    flat: "",
                    fab: "",
                    loading: _vm.loading.password
                  },
                  on: { click: _vm.onEditPassword }
                },
                [_c("v-icon", [_vm._v("mdi-pencil-outline")])],
                1
              )
            ],
            1
          ),
          _c("v-flex", { attrs: { xs12: "", md7: "", "order-md1": "" } }, [
            _c("p", { staticClass: "starling-text font-weight-bold" }, [
              _vm._v("************")
            ])
          ])
        ],
        1
      ),
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "", "align-center": "", "mt-2": "" } },
        [
          _c("v-flex", { attrs: { xs11: "", md4: "", "pr-3": "" } }, [
            _c("p", { staticClass: "starling-text text--disabled" }, [
              _vm._v(
                _vm._s(_vm.$t("app.profile.account.fields.profession.title"))
              )
            ])
          ]),
          _c(
            "v-flex",
            { attrs: { xs1: "", "order-md2": "" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "text--disabled ma-0",
                  attrs: {
                    small: "",
                    flat: "",
                    fab: "",
                    loading: _vm.loading.professionId
                  },
                  on: { click: _vm.onEditProfession }
                },
                [_c("v-icon", [_vm._v("mdi-pencil-outline")])],
                1
              )
            ],
            1
          ),
          _c("v-flex", { attrs: { xs12: "", md7: "", "order-md1": "" } }, [
            _c("p", { staticClass: "starling-text font-weight-bold" }, [
              _vm._v(_vm._s(_vm.professionLabel(_vm.user.professionId)))
            ])
          ])
        ],
        1
      ),
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "", "align-center": "", "mt-2": "" } },
        [
          _c("v-flex", { attrs: { xs11: "", md4: "", "pr-3": "" } }, [
            _c("p", { staticClass: "starling-text text--disabled" }, [
              _vm._v(
                _vm._s(_vm.$t("app.profile.account.fields.yearOfBirth.title"))
              )
            ])
          ]),
          _c(
            "v-flex",
            { attrs: { xs1: "", "order-md2": "" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "text--disabled ma-0",
                  attrs: {
                    small: "",
                    flat: "",
                    fab: "",
                    loading: _vm.loading.yearOfBirth
                  },
                  on: { click: _vm.onEditYearOfBirth }
                },
                [_c("v-icon", [_vm._v("mdi-pencil-outline")])],
                1
              )
            ],
            1
          ),
          _c("v-flex", { attrs: { xs12: "", md7: "", "order-md1": "" } }, [
            _c("p", { staticClass: "starling-text font-weight-bold" }, [
              _vm._v(
                _vm._s(
                  _vm.user.yearOfBirth ||
                    _vm.$t("app.profile.misc.no_disclosure")
                )
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "", "align-center": "", "mt-2": "" } },
        [
          _c("v-flex", { attrs: { xs11: "", md4: "", "pr-3": "" } }, [
            _c("p", { staticClass: "starling-text text--disabled" }, [
              _vm._v(_vm._s(_vm.$t("app.profile.account.fields.gender.title")))
            ])
          ]),
          _c(
            "v-flex",
            { attrs: { xs1: "", "order-md2": "" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "text--disabled ma-0",
                  attrs: {
                    small: "",
                    flat: "",
                    fab: "",
                    loading: _vm.loading.gender
                  },
                  on: { click: _vm.onEditGender }
                },
                [_c("v-icon", [_vm._v("mdi-pencil-outline")])],
                1
              )
            ],
            1
          ),
          _c("v-flex", { attrs: { xs12: "", md7: "", "order-md1": "" } }, [
            _c("p", { staticClass: "starling-text font-weight-bold" }, [
              _vm._v(_vm._s(_vm.genderLabel))
            ])
          ])
        ],
        1
      ),
      _vm.memberIdConfig && _vm.memberIdConfig.active
        ? _c("profile-text-field-modal", {
            attrs: {
              display: _vm.editing === "memberID",
              value: _vm.user.organizationMemberCode,
              title: _vm.$t("app.profile.account.fields.memberCode.title"),
              label: _vm.$t("app.profile.account.fields.memberCode.title"),
              rules: [
                function(v) {
                  return (
                    (v &&
                      v.length >= _vm.memberIdConfig.min &&
                        v.trim().length >= _vm.memberIdConfig.min) ||
                    _vm.$t("common.errors.required")
                  )
                },
                function(v) {
                  return (
                    (v &&
                      v.length <= _vm.memberIdConfig.max &&
                        v.trim().length <= _vm.memberIdConfig.max) ||
                    _vm.$t("common.errors.required")
                  )
                }
              ]
            },
            on: {
              close: function($event) {
                _vm.editing = null
              },
              save: _vm.onUpdateMemberID
            }
          })
        : _vm._e(),
      _c("profile-password-field-modal", {
        attrs: {
          display: _vm.editing === "password",
          title: _vm.$t("app.profile.account.fields.password.question")
        },
        on: {
          close: function($event) {
            _vm.editing = null
          },
          save: _vm.onUpdatePassword
        }
      }),
      _c("profile-select-field-modal", {
        attrs: {
          display: _vm.editing === "professionId",
          items: _vm.professionList,
          value: (_vm.user.professionId || -1) + "",
          title: _vm.$t("app.profile.account.fields.profession.question"),
          label: _vm.$t("app.profile.account.fields.profession.label"),
          autocomplete: ""
        },
        on: {
          close: function($event) {
            _vm.editing = null
          },
          save: _vm.onUpdateProfession
        }
      }),
      _c("profile-select-field-modal", {
        attrs: {
          display: _vm.editing === "yearOfBirth",
          items: _vm.years,
          value: (_vm.user.yearOfBirth || -1) + "",
          title: _vm.$t("app.profile.account.fields.yearOfBirth.question"),
          label: _vm.$t("app.profile.account.fields.yearOfBirth.label"),
          autocomplete: ""
        },
        on: {
          close: function($event) {
            _vm.editing = null
          },
          save: _vm.onUpdateYearOfBirth
        }
      }),
      _c("profile-select-with-custom-field-modal", {
        attrs: {
          display: _vm.editing === "gender",
          items: _vm.genders,
          value: _vm.user.gender,
          title: _vm.$t("app.profile.account.fields.gender.question"),
          label: _vm.$t("app.profile.account.fields.gender.label"),
          "custom-value": _vm.user.genderValue,
          "custom-value-item": "SELF_DESCRIBE",
          "custom-label": _vm.$t(
            "app.profile.account.fields.gender.custom_label"
          )
        },
        on: {
          close: function($event) {
            _vm.editing = null
          },
          save: _vm.onUpdateGender
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }