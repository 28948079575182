var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "radius-15 pa-2", attrs: { elevation: "3" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c("v-flex", [
            _c("h3", { staticClass: "starling-h3" }, [
              _vm._v(_vm._s(_vm.$t("app.profile.feedback_block.greeting")))
            ])
          ]),
          _c(
            "v-flex",
            { attrs: { xs12: "", "mt-1": "" } },
            [_c("v-divider")],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        {
          attrs: {
            row: "",
            wrap: "",
            "justify-center": "",
            "align-center": "",
            "mt-3": ""
          }
        },
        [
          _c(
            "v-flex",
            { attrs: { shrink: "" } },
            [
              _c(
                "primary-button",
                {
                  staticClass: "mb-2",
                  attrs: { large: "" },
                  on: { click: _vm.onEditFeedback }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t("app.profile.feedback_block.buttons.write")
                      ) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }