


































































































































































import Vue from 'vue';
import ProfilePasswordFieldModal from '@/views/profile/partials/profile-password-field-modal.vue';
import ProfileSelectFieldModal from '@/views/profile/partials/profile-select-field-modal.vue';
import ProfileSelectWithCustomFieldModal from '@/views/profile/partials/profile-select-with-custom-field-modal.vue';
import ProfileTextFieldModal from '@/views/profile/partials/profile-text-field-modal.vue';

interface IListItems {
  value: string,
  text: string,
}

export default Vue.extend({
  name: 'profile-account-details',
  components: {
    ProfilePasswordFieldModal,
    ProfileSelectFieldModal,
    ProfileSelectWithCustomFieldModal,
    ProfileTextFieldModal,
  },
  data() {
    return {
      editing: null as string | null,
      loading: {
        firstName: false,
        password: false,
        gender: false,
        genderValue: false,
        yearOfBirth: false,
        professionId: false,
        businessUnitId: false,
        memberID: false,
      },
      professionList: [] as Array<IListItems>,
      businessUnitList: [] as Array<IListItems>,
      memberIdConfig: null as { active: boolean, max: number, min: number } | null,
      memberIDMin: 0,
      memberIDMax: 20,
      businessUnitConfigLabel: null as string | null,
    };
  },
  computed: {
    user(): any {
      return this.$store.getters.user;
    },
    years(): Array<IListItems> {
      return [ ...[ ...Array(65).keys() ].map(e => {
        const val = e + new Date().getFullYear() - 80;
        return {
          text: val + '',
          value: val + '',
        };
      }).reverse(), { value: '-1', text: this.$t('app.profile.misc.no_disclosure') as string } ];
    },
    genders(): Array<IListItems> {
      return [
        { text: this.$t('common.constants.gender.MALE') as string, value: 'MALE' },
        { text: this.$t('common.constants.gender.FEMALE') as string, value: 'FEMALE' },
        { text: this.$t('common.constants.gender.NON_BINARY') as string, value: 'NON_BINARY' },
        {
          text: this.$t('app.profile.misc.self_describe', { value: this.user.genderValue }) as string,
          value: 'SELF_DESCRIBE',
        },
        { text: this.$t('app.profile.misc.no_disclosure') as string, value: 'UNKNOWN' },
      ];
    },
    genderLabel(): String | undefined {
      if (!this.user.gender) return;
      return this.genders.find(o => o.value === this.user.gender)?.text;
    },
  },
  mounted() {
    this.updateLists();
  },
  methods: {
    updateLists() {
      this.$emit('loading', true);
      Promise.all([
        this.$store.dispatch('generateProfessionsForProfile'),
        this.$store.dispatch('generateBusinessUnitsForProfile'),
        this.$store.dispatch('getMemberIdConfig', { id: this.$store.getters.user.id }),
      ]).then(([ resProfession, resBU, resMemberId ]) => {
        this.professionList = resProfession;
        this.businessUnitList = resBU;
        this.businessUnitConfigLabel = this.$store.getters.user.businessUnitLabel;
        this.memberIdConfig = resMemberId;
        if (this.memberIdConfig?.active) {
          this.memberIDMin = this.memberIdConfig.max;
          this.memberIDMax = this.memberIdConfig.min;
        }
      }).finally(() => {
        this.$emit('loading', false);
      });
    },
    professionLabel(productId: string) {
      if (!productId || !this.professionList?.length) {
        return '';
      }

      return this.professionList.find(o => o.value === productId)?.text;
    },
    businessUnitLabel(businessUnitId: string) {
      if (!businessUnitId || !this.businessUnitList?.length) {
        return '';
      }

      return this.businessUnitList.find(o => o.value === businessUnitId)?.text;
    },
    onEditMemberID() {
      this.$log.debug('Editing memberID');
      this.editing = 'memberID';
    },
    onUpdateMemberID(newValue: string) {
      this.$log.debug('Update memberID with value', newValue);
      if (newValue && newValue.length) {
        this.editing = null;
        this.loading.memberID = true;
        this.$store.dispatch('setOrganizationMemberCode', { userId: this.$store.getters.user.id, memberId: newValue })
          .finally(() => {
            this.loading.memberID = false;
          });
      }
    },
    onEditYearOfBirth() {
      this.$log.debug('Editing year of birth');
      this.editing = 'yearOfBirth';
    },
    onUpdateYearOfBirth(newValue: string) {
      this.$log.debug('Update year of birth with value', newValue);
      if (newValue) {
        this.editing = null;
        this.loading.yearOfBirth = true;
        this.$store.dispatch('editUser', { yearOfBirth: newValue }).then(() => {
          this.$store.commit('setNotification', { text: this.$t('app.profile.notifications.updated') });
        }).finally(() => {
          this.loading.yearOfBirth = false;
        });
      }
    },
    onEditProfession() {
      this.$log.debug('Editing occupation');
      this.editing = 'professionId';
    },
    onUpdateProfession(newValue: string) {
      this.$log.debug('Update occupation value', newValue);
      if (newValue) {
        this.editing = null;
        this.loading.professionId = true;
        this.$store.dispatch('editUser', { professionId: newValue }).then(() => {
          this.$store.commit('setNotification', { text: this.$t('app.profile.notifications.updated') });
        }).finally(() => {
          this.loading.professionId = false;
        });
      }
    },
    onEditBusinessUnit() {
      this.$log.debug('Editing business unit');
      this.editing = 'businessUnitId';
    },
    onUpdateBusinessUnit(newValue: string) {
      this.$log.debug('Update business unit value', newValue);
      if (newValue) {
        this.editing = null;
        this.loading.businessUnitId = true;
        this.$store.dispatch('editUser', { businessUnitId: newValue }).then(() => {
          this.$store.commit('setNotification', { text: this.$t('app.profile.notifications.updated') });
        }).finally(() => {
          this.loading.businessUnitId = false;
        });
      }
    },
    onEditGender() {
      this.$log.debug('Editing gender');
      this.editing = 'gender';
    },
    onUpdateGender(newValue: { value: string, custom: string }) {
      this.$log.debug('Update gender with value', newValue);
      if (newValue) {
        this.editing = null;
        this.loading.gender = true;
        this.$store.dispatch('editUser', { gender: newValue.value, genderValue: newValue.custom }).then(res => {
          this.$store.commit('setNotification', { text: this.$t('app.profile.notifications.updated') });
        }).finally(() => {
          this.loading.gender = false;
        });
      }
    },
    onEditPassword() {
      this.$log.debug('Editing password');
      this.editing = 'password';
    },
    onUpdatePassword(payload: { currentPassword: string, newPassword: string }) {
      this.$log.debug('Update password with values', payload);
      this.editing = null;
      // change password in the locale DB then in Cognito
      this.loading.password = true;
      this.$store.dispatch('identity/changePassword', payload).then(res => {
        this.$log.debug('Changed password', res);
        if (res.status === 204 || res.status === 200) {
          this.$store.commit('setNotification', { text: this.$t('app.profile.notifications.password_changed') });
        } else {
          this.$store.commit('setNotification', { text: this.$t('app.profile.notifications.password_not_changed'), color: 'error' });
        }
        return Promise.resolve();
      }).finally(() => {
        this.loading.password = false;
      });
    },
  },
});
