












import Vue from 'vue';
import ProfileHeader from '@/views/profile/ProfileHeader.vue';

export default Vue.extend({
  name: 'profile-view',
  components: {
    ProfileHeader,
  },
});
