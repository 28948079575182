<template>
<!-- eslint-disable-next-line vue/no-mutating-props -->
<v-dialog v-model="display" persistent max-width="600px" content-class="radius-15">
  <v-card flat class="pa-2">
    <v-card-title v-if="title">
      <h1 class="headline primary--text">{{ title }}</h1>
    </v-card-title>
    <v-card-text class="pa-0">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-container fluid>
          <v-layout wrap>
            <v-flex xs12>
              <v-select
                v-model="currentValue"
                :label="label"
                :rules="rules"
                :items="items"
                type="">
              </v-select>
            </v-flex>
            <v-flex xs12>
              <v-text-field v-if="currentValue === customValueItem"
                v-model="currentCustomValue"
                :label="customLabel"
                :rules="customRules">
              </v-text-field>
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-layout row align-center justify-space-around gap-xs-3>
        <v-flex shrink>
          <v-btn flat round @click="onClose">
            {{$t('common.actions.cancel')}}
          </v-btn>
        </v-flex>
        <v-flex shrink>
          <primary-button :disabled="!valid" @click="onSave">
            {{$t('common.actions.save')}}
          </primary-button>
        </v-flex>
      </v-layout>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
import PrimaryButton from '@/views/components/button/primary-button';
export default {
  name: 'profile-select-with-custom-field-modal',
  components: { PrimaryButton },
  props: {
    display: {
      type: Boolean,
      required: false,
      default: false,
    },
    items: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      required: false,
      default: null,
    },
    customValue: {
      type: String,
      required: false,
    },
    customValueItem: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    customLabel: {
      type: String,
      required: false,
      default: null,
    },
    rules: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      currentValue: null,
      currentCustomValue: null,
      valid: true,
      customRules: [
        v => (v && v.length > 0) || this.$t('common.errors.required'),
        v => (v && v.length <= 100) || this.$t('common.errors.max100'),
      ],
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.currentValue = newValue;
      },
    },
    customValue: {
      immediate: true,
      handler(newValue) {
        this.currentCustomValue = newValue;
      },
    },
    currentValue: {
      handler(newValue) {
        if (newValue !== this.customValueItem) {
          this.currentCustomValue = null;
        } else {
          setTimeout(() => {
            this.$refs.form.validate();
          }, 50);
        }
      },
    },
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    onSave() {
      if (this.$refs.form.validate()) {
        this.$emit('save', { value: this.currentValue, custom: this.currentCustomValue });
      }
    },
  },
};
</script>
