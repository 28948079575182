



































































import Vue from 'vue';
import StarlingAvatarInput from '@/views/avatar/starling-avatar-input.vue';
import { AvatarOption } from '@/views/avatar/types';
import ProfileTextFieldModal from '@/views/profile/partials/profile-text-field-modal.vue';

export default Vue.extend({
  name: 'profile-community-details',
  components: { ProfileTextFieldModal, StarlingAvatarInput },
  data() {
    return {
      loading: {
        avatar: false,
        codeName: false,
      },
      editing: null as String | null,
    };
  },
  computed: {
    user(): any {
      return this.$store.getters.user;
    },
  },
  methods: {
    onUpdateAvatar(event: { image: string, options: AvatarOption }) {
      this.loading.avatar = true;
      this.$store.dispatch('editAvatar', { avatar: JSON.stringify(event.options), image: event.image }).then(() => {
        this.$store.commit('setNotification', { text: this.$t('app.profile.notifications.updated') });
      }).finally(() => {
        this.loading.avatar = false;
      });
    },
    onUpdateCodeName(newValue: string) {
      this.$log.debug('Update first name with value', newValue);
      if (newValue && newValue.length) {
        this.editing = null;
        this.loading.codeName = true;
        this.$store.dispatch('editUser', { codeName: newValue }).then(() => {
          this.$store.commit('setNotification', { text: this.$t('app.profile.notifications.updated') });
        }).finally(() => {
          this.loading.codeName = false;
        });
      }
    },
  },
});
