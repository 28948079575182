<template>
<!-- eslint-disable-next-line vue/no-mutating-props -->
<v-dialog v-model="display" persistent max-width="600px" content-class="radius-15">
  <v-card flat class="pa-2">
    <v-card-title v-if="title">
      <h1 class="headline primary--text">{{ title }}</h1>
    </v-card-title>
    <v-card-text class="pa-0">
      <v-form ref="form" v-model="valid" lazy-validation autocomplete="off">
        <v-container fluid>
          <v-layout wrap>
            <v-flex xs12>
              <registration-field-password
                v-model="newPassword"
                :confirmation="newPasswordConfirmation"
                :label="$t('app.profile.account.fields.password.new_password')"
                @change="validate(false)"
                @keyup="validate(false)">
              </registration-field-password>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="newPasswordConfirmation"
                autocomplete="off"
                :type="showNewPasswordConfirmation?'text':'password'"
                :rules="rules.password" @change="validate()"
                :label="$t('app.profile.account.fields.password.confirm_password')"
                :append-icon="`$vuetify.icons.starling_eye_${showNewPasswordConfirmation ? 'opened' : 'closed'}`"
                @keyup="validate()"
                @click:append="showNewPasswordConfirmation = !showNewPasswordConfirmation">
              </v-text-field>
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-layout row align-center justify-space-around gap-xs-3>
        <v-flex shrink>
          <v-btn flat round @click="onClose">
            {{$t('common.actions.cancel')}}
          </v-btn>
        </v-flex>
        <v-flex shrink>
          <primary-button :disabled="!valid" @click="onSave">
            {{$t('common.actions.save')}}
          </primary-button>
        </v-flex>
      </v-layout>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
import { VALIDATION_REGEX } from '@/scripts/api';
import RegistrationFieldPassword from '@/views/landing/register/components/registration-field-password';
import PrimaryButton from '@/views/components/button/primary-button';
export default {
  name: 'profile-password-field-modal',
  components: { PrimaryButton, RegistrationFieldPassword },
  props: {
    display: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      valid: false,
      newPassword: '',
      newPasswordConfirmation: '',
      showNewPasswordConfirmation: false,
      showNewPassword: false,
      rules: {
        password: [
          v => (v && v.length > 0) || this.$t('common.errors.required'),
          v => {
            return (
              VALIDATION_REGEX.PASSWORD.test(v) || this.$t('common.errors.password')
            );
          },
          v => (v && v.length <= 60) || this.$t('common.errors.max60'),
          v => this.newPassword === this.newPasswordConfirmation || this.$t('common.errors.passwordMatch'),
        ],
      },
    };
  },
  methods: {
    reset() {
      this.newPassword = null;
      this.newPasswordConfirmation = null;
      this.showNewPasswordConfirmation = false;
      this.showNewPassword = false;
      this.$refs.form.resetValidation();
    },
    validate() {
      this.$refs.form.validate();
    },
    onClose() {
      this.$emit('close');
      this.reset();
    },
    onSave() {
      if (this.$refs.form.validate()) {
        this.$emit('save', { password: this.newPassword });
        this.reset();
      }
    },
  },
};
</script>
