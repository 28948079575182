var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "radius-15 pa-2", attrs: { elevation: "3" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c("v-flex", [
            _c("h3", { staticClass: "starling-h3" }, [
              _vm._v(_vm._s(_vm.$t("app.profile.settings.title")))
            ])
          ]),
          _c(
            "v-flex",
            { attrs: { xs12: "", "mt-1": "" } },
            [_c("v-divider")],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "", "align-center": "", "mt-2": "" } },
        [
          _c("v-flex", { attrs: { xs11: "", md4: "", "pr-3": "" } }, [
            _c("p", { staticClass: "starling-text text--disabled" }, [
              _vm._v(
                _vm._s(_vm.$t("app.profile.settings.fields.language.title"))
              )
            ])
          ]),
          _c(
            "v-flex",
            { attrs: { xs1: "", "order-md2": "" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "text--disabled ma-0",
                  attrs: {
                    small: "",
                    flat: "",
                    fab: "",
                    loading: _vm.loading.locale
                  },
                  on: { click: _vm.onEditLocale }
                },
                [_c("v-icon", [_vm._v("mdi-pencil-outline")])],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "", md7: "", "order-xs2": "", "order-md1": "" } },
            [
              _c("p", { staticClass: "starling-text font-weight-bold" }, [
                _vm._v(_vm._s(_vm.localeLabel(_vm.user.locale)))
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "", "align-center": "", "mt-2": "" } },
        [
          _c("v-flex", { attrs: { xs11: "", md4: "", "pr-3": "" } }, [
            _c("p", { staticClass: "starling-text text--disabled" }, [
              _vm._v(
                _vm._s(_vm.$t("app.profile.settings.fields.timezone.title"))
              )
            ])
          ]),
          _c(
            "v-flex",
            { attrs: { xs1: "", "order-md2": "" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "text--disabled ma-0",
                  attrs: {
                    small: "",
                    flat: "",
                    fab: "",
                    loading: _vm.loading.timezone
                  },
                  on: { click: _vm.onEditTimezone }
                },
                [_c("v-icon", [_vm._v("mdi-pencil-outline")])],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "", md7: "", "order-xs2": "", "order-md1": "" } },
            [
              _c("p", { staticClass: "starling-text font-weight-bold" }, [
                _vm._v(_vm._s(_vm.timezoneLabel(_vm.user.timezone)))
              ])
            ]
          )
        ],
        1
      ),
      _vm.testEnvironment
        ? _c(
            "v-layout",
            { attrs: { row: "", wrap: "", "align-center": "", "mt-2": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("v-switch", {
                    staticClass: "ma-0 pa-0",
                    attrs: {
                      "hide-details": "",
                      label: _vm.$t("app.profile.settings.fields.enable.title")
                    },
                    model: {
                      value: _vm.translationEnabled,
                      callback: function($$v) {
                        _vm.translationEnabled = $$v
                      },
                      expression: "translationEnabled"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "", "mt-2": "" } },
                [_c("v-divider")],
                1
              ),
              _c("v-flex", { attrs: { xs12: "", "mt-2": "" } }, [
                _c("p", { staticClass: "starling-text" }, [
                  _c("span", { staticClass: "text--disabled" }, [
                    _vm._v("Version: ")
                  ]),
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v(_vm._s(_vm.version))
                  ])
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "", "mt-2": "" } },
        [
          _c("v-flex", { attrs: { xs12: "" } }, [_c("v-divider")], 1),
          _c(
            "v-flex",
            { attrs: { xs12: "", "mt-2": "", "text-center": "" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "ma-0",
                  attrs: { round: "", flat: "", large: "" },
                  on: {
                    click: function($event) {
                      _vm.showDeleteAccountModal = true
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t("app.profile.account.delete_account.button")
                      ) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.$vuetify.breakpoint.smAndDown
        ? _c(
            "v-layout",
            { attrs: { row: "", wrap: "", "mt-2": "" } },
            [
              _c("v-flex", { attrs: { xs12: "" } }, [_c("v-divider")], 1),
              _c(
                "v-flex",
                { attrs: { xs12: "", "mt-2": "", "text-center": "" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "ma-0",
                      attrs: { round: "", flat: "", large: "" },
                      on: { click: _vm.logout }
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-2",
                          staticStyle: { height: "16px", width: "16px" }
                        },
                        [_vm._v("$vuetify.icons.starling_logout")]
                      ),
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("common.actions.logout")) +
                          "\n      "
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("profile-select-field-modal", {
        attrs: {
          display: _vm.editing === "locale",
          items: _vm.localeList,
          value: _vm.user.locale,
          title: _vm.$t("app.profile.settings.fields.language.question"),
          label: _vm.$t("app.profile.settings.fields.language.label")
        },
        on: {
          close: function($event) {
            _vm.editing = null
          },
          save: _vm.onUpdateLocale
        }
      }),
      _c("profile-select-field-modal", {
        attrs: {
          display: _vm.editing === "timezone",
          items: _vm.timezoneList,
          value: _vm.user.timezone,
          title: _vm.$t("app.profile.settings.fields.timezone.question"),
          label: _vm.$t("app.profile.settings.fields.timezone.label")
        },
        on: {
          close: function($event) {
            _vm.editing = null
          },
          save: _vm.onUpdateTimezone
        }
      }),
      _c("custom-confirmation-modal", {
        attrs: {
          display: _vm.showDeleteAccountModal,
          title: _vm.$t("app.profile.account.delete_account.title"),
          description: _vm.$t("app.profile.account.delete_account.description"),
          primaryConfirmLabel: _vm.$t(
            "app.profile.account.delete_account.confirm_button"
          ),
          secondaryCancelLabel: _vm.$t("common.actions.cancel"),
          primaryConfirmDisabled: !_vm.valid
        },
        on: {
          "cancel-event": _vm.onCancelDeleteAccount,
          "confirm-event": _vm.onConfirmDeleteAccount
        },
        scopedSlots: _vm._u([
          {
            key: "afterDescription",
            fn: function() {
              return [
                _c("v-flex", { attrs: { xs12: "", "mt-3": "" } }, [
                  _c("p", {
                    staticClass: "starling-body",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t(
                          "app.profile.account.delete_account.confirmation.label"
                        )
                      )
                    }
                  })
                ]),
                _c(
                  "v-flex",
                  { attrs: { xs12: "" } },
                  [
                    _c(
                      "v-form",
                      {
                        ref: "deleteAccountForm",
                        attrs: { autocomplete: "off" },
                        model: {
                          value: _vm.valid,
                          callback: function($$v) {
                            _vm.valid = $$v
                          },
                          expression: "valid"
                        }
                      },
                      [
                        _c("v-text-field", {
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            placeholder: _vm.$t(
                              "app.profile.account.delete_account.confirmation.safe_word"
                            ),
                            rules: _vm.rules.confirmation
                          },
                          on: { change: _vm.validate, keyup: _vm.validate },
                          model: {
                            value: _vm.safeWord,
                            callback: function($$v) {
                              _vm.safeWord = $$v
                            },
                            expression: "safeWord"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }