
























import Vue from 'vue';
import PrimaryButton from '@/views/components/button/primary-button.vue';

export default Vue.extend({
  name: 'profile-feedback',
  components: {
    PrimaryButton,
  },
  methods: {
    onEditFeedback() {
      window.location.href = this.$t('app.profile.feedback.mailto') as string;
    },
  },
});
