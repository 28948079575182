var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { staticClass: "prof", attrs: { wrap: "", "pt-3": "", "gap-xs-3": "" } },
    [
      _c("v-flex", { attrs: { xs12: "" } }, [_c("profile-header")], 1),
      _c("v-flex", { attrs: { xs12: "" } }, [_c("router-view")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }