

































































import Vue from 'vue';
import customConfirmationModal from '@/views/components/utilities/custom-confirmation-modal.vue';
import { WebinarRegistrant } from '@/scripts/store/modules/webinar/types';

export default Vue.extend({
  name: 'profile-webinars',
  components: { customConfirmationModal },
  data() {
    return {
      loading: false,
      webinars: undefined as WebinarRegistrant[] | undefined,
      selectedWebinar: undefined as WebinarRegistrant | undefined,
      showConfirmation: false,
      showErrorMessage: false,
    };
  },
  computed: {
    showList(): boolean {
      return !!this.webinars?.length;
    },
  },
  mounted() {
    this.getWebinarList();
  },
  methods: {
    getWebinarList() {
      this.loading = true;
      this.selectedWebinar = undefined;

      this.$store.dispatch('webinar/getAvailableWebinars')
        .then((res: WebinarRegistrant[]) => {
          this.webinars = res;
        }).finally(() => {
          this.loading = false;
        });
    },
    handleRegistrationClick(webinar: WebinarRegistrant) {
      const previousValue = !webinar.isRegistered;

      if (this.loading) {
        this.$nextTick(() => {
          webinar.isRegistered = previousValue;
        });
        return;
      }

      if (previousValue) {
        this.selectedWebinar = webinar;
        this.showConfirmation = true;
        this.$nextTick(() => {
          webinar.isRegistered = previousValue;
        });
        return;
      }

      this.loading = true;
      this.showErrorMessage = false;

      this.$store.dispatch('webinar/registerWebinar', webinar.id)
        .then(res => {
          if (res?.status >= 400) throw Error();
        }).catch(() => {
          this.showErrorMessage = true;
        }).finally(() => {
          this.loading = false;
          this.getWebinarList();
        });
    },
    onConfirmCancel() {
      this.showConfirmation = false;
    },
    onConfirmDelete(webinar: WebinarRegistrant) {
      this.loading = true;
      this.showConfirmation = false;
      this.showErrorMessage = false;

      this.$store.dispatch('webinar/unregisterWebinar', this.selectedWebinar?.id)
        .then(res => {
          if (res?.status >= 400) throw Error();
        }).catch(() => {
          this.showErrorMessage = true;
        }).finally(() => {
          this.loading = false;
          this.getWebinarList();
        });
    },
  },
});
