var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "radius-15 pa-2", attrs: { elevation: "3" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c("v-flex", [
            _c("h3", { staticClass: "starling-h3" }, [
              _vm._v(_vm._s(_vm.$t("app.profile.community.title")))
            ])
          ]),
          _c(
            "v-flex",
            { attrs: { xs12: "", "mt-1": "" } },
            [_c("v-divider")],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "", "align-center": "", "mt-2": "" } },
        [
          _c("v-flex", { attrs: { xs12: "" } }, [
            _vm._v(
              "\n     " +
                _vm._s(_vm.$t("app.profile.community.tagline")) +
                "\n    "
            )
          ]),
          _c(
            "v-flex",
            { attrs: { xs12: "", "text-center": "" } },
            [
              _c(
                "v-layout",
                {
                  attrs: {
                    row: "",
                    wrap: "",
                    "align-center": "",
                    "justify-center": ""
                  }
                },
                [
                  _c(
                    "v-flex",
                    {
                      attrs: {
                        xs8: "",
                        sm6: "",
                        md5: "",
                        lg3: "",
                        "pa-3": "",
                        "text-center": ""
                      }
                    },
                    [
                      _c("starling-avatar-input", {
                        attrs: {
                          loading: _vm.loading.avatar,
                          value:
                            _vm.user.avatar && _vm.user.avatar.length
                              ? JSON.parse(_vm.user.avatar)
                              : null
                        },
                        on: { saved: _vm.onUpdateAvatar }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      attrs: {
                        xs12: "",
                        sm12: "",
                        md12: "",
                        lg8: "",
                        "align-center": "",
                        "justify-center": "",
                        "text-center": ""
                      }
                    },
                    [
                      _c(
                        "v-layout",
                        {
                          attrs: {
                            row: "",
                            wrap: "",
                            "align-center": "",
                            "justify-center": ""
                          }
                        },
                        [
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs11: "",
                                sm10: "",
                                md9: "",
                                "text-center": ""
                              }
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass: " font-weight-bold ma-0",
                                  class: {
                                    "starling-h4":
                                      _vm.$vuetify.breakpoint.mdAndUp,
                                    "starling-body":
                                      _vm.$vuetify.breakpoint.smOnly,
                                    "starling-text":
                                      _vm.$vuetify.breakpoint.xsOnly
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.user.codeName)
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs1: "",
                                sm2: "",
                                md3: "",
                                "text-right": ""
                              }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "text--disabled ma-0 px-0",
                                  attrs: {
                                    flat: "",
                                    fab: "",
                                    small: _vm.$vuetify.breakpoint.mdAndDown,
                                    loading: _vm.loading.codeName
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.editing = "codeName"
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("mdi-pencil-outline")])],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("profile-text-field-modal", {
                        attrs: {
                          display: _vm.editing === "codeName",
                          value: _vm.user.codeName,
                          title: _vm.$t(
                            "app.profile.account.fields.codeName.question"
                          ),
                          label: _vm.$t(
                            "app.profile.account.fields.codeName.label"
                          ),
                          hint: _vm.$t(
                            "app.profile.account.fields.codeName.hint"
                          ),
                          rules: [
                            function(v) {
                              return (
                                (v && v.length > 0 && v.trim().length > 0) ||
                                _vm.$t("common.errors.required")
                              )
                            }
                          ],
                          provider: {
                            icon: "$vuetify.icons.starling_randomize",
                            title: _vm.$t(
                              "app.profile.account.fields.codeName.provider.title"
                            ),
                            apply: function() {
                              return this$1.$store
                                .dispatch("generateRegistrationCodename")
                                .then(function(res) {
                                  return res.codename
                                })
                            }
                          }
                        },
                        on: {
                          close: function($event) {
                            _vm.editing = null
                          },
                          save: _vm.onUpdateCodeName
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }