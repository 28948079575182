






































































































































import Vue from 'vue';
import { disableTranslation, enableTranslation, isTranslationEnabled } from '@/scripts/lang';
import ProfileSelectFieldModal from '@/views/profile/partials/profile-select-field-modal.vue';
import CustomConfirmationModal from '@/views/components/utilities/custom-confirmation-modal.vue';

interface IListItems {
  value: string,
  text: string,
}

export default Vue.extend({
  name: 'profile-global-settings',
  components: {
    ProfileSelectFieldModal,
    CustomConfirmationModal,
  },
  data() {
    return {
      editing: null as string | null,
      loading: {
        locale: false,
        timezone: false,
      },
      translationEnabled: isTranslationEnabled(),
      timezoneList: [] as Array<IListItems>,
      localeList: [] as Array<IListItems>,
      version: null as string | null,
      showDeleteAccountModal: false,
      valid: false,
      safeWord: null as string | null,
      rules: {
        confirmation: [
          (v: string) => (v && v.length > 0) || this.$t('common.errors.required'),
          (v: string) => (v && v.toLowerCase().trim().replace(/[\s\t]+/, ' ') === this.$t('app.profile.account.delete_account.confirmation.safe_word')) || '',
        ],
      },
    };
  },
  computed: {
    user(): any {
      return this.$store.getters.user;
    },
    deleteAccountForm(): Vue & { resetValidation: () => void, validate: () => boolean } {
      return this.$refs.deleteAccountForm as Vue & { resetValidation: () => void, validate: () => boolean };
    },
  },
  watch: {
    translationEnabled: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          enableTranslation();
        } else {
          disableTranslation();
        }
      },
    },
  },
  mounted() {
    this.updateLists();
    this.capacitorGetVersion().then(version => {
      this.version = version;
    });
  },
  methods: {
    updateLists() {
      this.$emit('loading', true);
      Promise.all([
        this.$store.dispatch('generateTimezonesForProfile'),
        this.$store.dispatch('generateLocalesForProfile'),
      ]).then(([ resTimezones, resLocales ]) => {
        this.timezoneList = resTimezones;
        this.localeList = resLocales.map((l: { name: string, language: string }) => {
          return {
            text: l.name,
            value: l.language,
          };
        });
      }).finally(() => {
        this.$emit('loading', false);
      });
    },
    localeLabel(localeId: string) {
      if (!localeId || this.localeList.length === 0) {
        return '';
      }
      return this.localeList.find(o => o.value === localeId)?.text;
    },
    timezoneLabel(timezoneId: string) {
      if (!timezoneId || this.timezoneList.length === 0) {
        return ' ';
      }

      return this.timezoneList.find(o => o.value === timezoneId)?.text;
    },
    onEditLocale() {
      this.$log.debug('Editing locale');
      this.editing = 'locale';
    },
    onUpdateLocale(newValue: string) {
      this.$log.debug('Update locale with value', newValue);
      if (newValue) {
        this.editing = null;
        this.loading.locale = true;
        this.$store.dispatch('editUser', { locale: newValue }).then(() => {
          this.$store.commit('setNotification', { text: this.$t('app.profile.notifications.updated') });
          this.updateLists();
          this.$emit('onLocaleChanged');
        }).finally(() => {
          this.loading.locale = false;
        });
      }
    },
    onEditTimezone() {
      this.$log.debug('Editing Timezone');
      this.editing = 'timezone';
    },
    onUpdateTimezone(newValue: string) {
      this.$log.debug('Update Timezone with value', newValue);
      if (newValue) {
        this.editing = null;
        this.loading.timezone = true;
        this.$store.dispatch('editUser', { timezone: newValue }).then(res => {
          this.$store.commit('setNotification', { text: this.$t('app.profile.notifications.updated') });
        }).finally(() => {
          this.loading.timezone = false;
        });
      }
    },
    logout() {
      this.$store.dispatch('identity/logout').then(res => {
        this.$router.push({ name: 'presentation' });
      });
    },
    validate() {
      this.deleteAccountForm.validate();
    },
    onCancelDeleteAccount() {
      this.showDeleteAccountModal = false;
      this.safeWord = null;
      this.deleteAccountForm.resetValidation();
    },
    onConfirmDeleteAccount() {
      if (this.deleteAccountForm.validate()) {
        this.$store.dispatch('identity/deleteAccount').then(() => {
          this.logout();
        }).catch((response) => {
          this.$log.error('An error occured during account deletion', response.json());
        });
      }
    },
  },
});
