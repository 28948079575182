var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "profile-header radius-15 pa-2", attrs: { elevation: "3" } },
    [
      _c(
        "v-card-text",
        { staticClass: "pa-0 pb-2" },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "", "align-center": "", "justify-center": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "", "text-center": "", "order-md2": "" } },
                [
                  _c(
                    "h1",
                    { staticClass: "starling-h1" },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.user.firstName) +
                          "\n          "
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "text--disabled",
                          staticStyle: { margin: "-10px -50px 0 10px" },
                          attrs: {
                            small: "",
                            flat: "",
                            fab: "",
                            loading: _vm.loading
                          },
                          on: { click: _vm.onEditFirstName }
                        },
                        [_c("v-icon", [_vm._v("mdi-pencil-outline")])],
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "", "text-center": "", "order-md3": "" } },
                [
                  _c("p", { staticClass: "starling-body" }, [
                    _vm._v(_vm._s(_vm.user.organization))
                  ])
                ]
              ),
              _vm.joinedIn
                ? _c(
                    "v-flex",
                    {
                      class: {
                        "text-center mt-2": _vm.$vuetify.breakpoint.smAndDown,
                        "text-right": _vm.$vuetify.breakpoint.mdAndUp
                      },
                      attrs: { xs12: "", "order-md1": "" }
                    },
                    [
                      _c(
                        "p",
                        { staticClass: "starling-explanatory text--disabled" },
                        [_vm._v(_vm._s(_vm.joinedIn))]
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          ),
          _c("profile-text-field-modal", {
            attrs: {
              display: _vm.editing,
              value: _vm.user.firstName,
              title: _vm.$t("app.profile.header.fields.name.question"),
              label: _vm.$t("app.profile.header.fields.name.label"),
              rules: [
                function(v) {
                  return (
                    (v && v.length > 0 && v.trim().length > 0) ||
                    _vm.$t("common.errors.required")
                  )
                }
              ]
            },
            on: {
              close: function($event) {
                _vm.editing = false
              },
              save: _vm.onUpdateFirstName
            }
          })
        ],
        1
      ),
      _c(
        "v-card-actions",
        { staticClass: "pa-0 pt-2" },
        [
          _c(
            "v-layout",
            { attrs: { "align-center": "", "justify-center": "" } },
            [
              _c(
                "v-flex",
                { attrs: { shrink: "" } },
                [
                  _vm._l(_vm.$t("app.profile.navigation"), function(
                    item,
                    index
                  ) {
                    return [
                      _c(
                        "router-link",
                        {
                          key: index,
                          staticClass: "starling-text profile-link",
                          attrs: { tag: "v-btn", to: { name: item.path } }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.label) +
                              "\n          "
                          )
                        ]
                      )
                    ]
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }