var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "profile-communication radius-15 pa-2",
      attrs: { elevation: "3" }
    },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c("v-flex", [
            _c("h3", { staticClass: "starling-h3" }, [
              _vm._v(_vm._s(_vm.$t("app.profile.communication.title")))
            ])
          ]),
          _c(
            "v-flex",
            { attrs: { xs12: "", "mt-1": "" } },
            [_c("v-divider")],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "", "mt-2": "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "" } },
                [
                  _c(
                    "v-flex",
                    {
                      attrs: {
                        xs3: "",
                        sm2: "",
                        "text-center": "",
                        "offset-xs6": "",
                        "offset-sm8": ""
                      }
                    },
                    [
                      _c(
                        "p",
                        { staticClass: "starling-body StarlingPrimary2--text" },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("app.profile.communication.email"))
                          )
                        ]
                      )
                    ]
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs3: "", sm2: "", "text-center": "" } },
                    [
                      _c(
                        "p",
                        { staticClass: "starling-body StarlingPrimary2--text" },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("app.profile.communication.app"))
                          )
                        ]
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "", "align-center": "" } },
        [
          _c("v-flex", { attrs: { xs12: "" } }, [
            _c("p", { staticClass: "starling-body" }, [
              _vm._v(_vm._s(_vm.$t("app.profile.communication.comments.title")))
            ])
          ]),
          _c(
            "v-flex",
            { attrs: { xs12: "", "mt-2": "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "" } },
                [
                  _c("v-flex", { attrs: { xs6: "", sm8: "" } }, [
                    _c("p", { staticClass: "starling-text text--disabled" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("app.profile.communication.comments.reactions")
                        )
                      )
                    ])
                  ]),
                  _c(
                    "v-flex",
                    { attrs: { xs3: "", sm2: "" } },
                    [
                      _c("v-switch", {
                        staticClass: "ma-0 justify-center",
                        attrs: { "hide-details": "", loading: _vm.loading },
                        on: {
                          change: function($event) {
                            return _vm.updateCommunications("EMAIL")
                          }
                        },
                        model: {
                          value: _vm.emailSettings.commentReactions,
                          callback: function($$v) {
                            _vm.$set(_vm.emailSettings, "commentReactions", $$v)
                          },
                          expression: "emailSettings.commentReactions"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs3: "", sm2: "" } },
                    [
                      _c("v-switch", {
                        staticClass: "ma-0 justify-center",
                        attrs: { "hide-details": "", loading: _vm.loading },
                        on: {
                          change: function($event) {
                            return _vm.updateCommunications("PUSH")
                          }
                        },
                        model: {
                          value: _vm.pushSettings.commentReactions,
                          callback: function($$v) {
                            _vm.$set(_vm.pushSettings, "commentReactions", $$v)
                          },
                          expression: "pushSettings.commentReactions"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "", "mt-2": "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "" } },
                [
                  _c("v-flex", { attrs: { xs6: "", sm8: "" } }, [
                    _c("p", { staticClass: "starling-text text--disabled" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("app.profile.communication.comments.replies")
                        )
                      )
                    ])
                  ]),
                  _c(
                    "v-flex",
                    { attrs: { xs3: "", sm2: "" } },
                    [
                      _c("v-switch", {
                        staticClass: "ma-0 justify-center",
                        attrs: { "hide-details": "", loading: _vm.loading },
                        on: {
                          change: function($event) {
                            return _vm.updateCommunications("EMAIL")
                          }
                        },
                        model: {
                          value: _vm.emailSettings.commentReplies,
                          callback: function($$v) {
                            _vm.$set(_vm.emailSettings, "commentReplies", $$v)
                          },
                          expression: "emailSettings.commentReplies"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs3: "", sm2: "" } },
                    [
                      _c("v-switch", {
                        staticClass: "ma-0 justify-center",
                        attrs: { "hide-details": "", loading: _vm.loading },
                        on: {
                          change: function($event) {
                            return _vm.updateCommunications("PUSH")
                          }
                        },
                        model: {
                          value: _vm.pushSettings.commentReplies,
                          callback: function($$v) {
                            _vm.$set(_vm.pushSettings, "commentReplies", $$v)
                          },
                          expression: "pushSettings.commentReplies"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "", "align-center": "", "mt-3": "" } },
        [
          _c("v-flex", { attrs: { xs12: "" } }, [
            _c("p", { staticClass: "starling-body" }, [
              _vm._v(_vm._s(_vm.$t("app.profile.communication.progress.title")))
            ])
          ]),
          _c(
            "v-flex",
            { attrs: { xs12: "", "mt-2": "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "" } },
                [
                  _c("v-flex", { attrs: { xs6: "", sm8: "" } }, [
                    _c("p", { staticClass: "starling-text text--disabled" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("app.profile.communication.progress.session")
                        )
                      )
                    ])
                  ]),
                  _c(
                    "v-flex",
                    { attrs: { xs3: "", sm2: "" } },
                    [
                      _c("v-switch", {
                        staticClass: "ma-0 justify-center",
                        attrs: { "hide-details": "", loading: _vm.loading },
                        on: {
                          change: function($event) {
                            return _vm.updateCommunications("EMAIL")
                          }
                        },
                        model: {
                          value: _vm.emailSettings.sessionReminder,
                          callback: function($$v) {
                            _vm.$set(_vm.emailSettings, "sessionReminder", $$v)
                          },
                          expression: "emailSettings.sessionReminder"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs3: "", sm2: "" } },
                    [
                      _c("v-switch", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false"
                          }
                        ],
                        staticClass: "ma-0 justify-center",
                        attrs: { "hide-details": "", loading: _vm.loading },
                        on: {
                          change: function($event) {
                            return _vm.updateCommunications("PUSH")
                          }
                        },
                        model: {
                          value: _vm.pushSettings.sessionReminder,
                          callback: function($$v) {
                            _vm.$set(_vm.pushSettings, "sessionReminder", $$v)
                          },
                          expression: "pushSettings.sessionReminder"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "", "mt-2": "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "" } },
                [
                  _c("v-flex", { attrs: { xs6: "", sm8: "" } }, [
                    _c("p", { staticClass: "starling-text text--disabled" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("app.profile.communication.progress.checkup")
                        )
                      )
                    ])
                  ]),
                  _c(
                    "v-flex",
                    { attrs: { xs3: "", sm2: "" } },
                    [
                      _c("v-switch", {
                        staticClass: "ma-0 justify-center",
                        attrs: { "hide-details": "", loading: _vm.loading },
                        on: {
                          change: function($event) {
                            return _vm.updateCommunications("EMAIL")
                          }
                        },
                        model: {
                          value: _vm.emailSettings.checkUpReminder,
                          callback: function($$v) {
                            _vm.$set(_vm.emailSettings, "checkUpReminder", $$v)
                          },
                          expression: "emailSettings.checkUpReminder"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs3: "", sm2: "" } },
                    [
                      _c("v-switch", {
                        staticClass: "ma-0 justify-center",
                        attrs: { "hide-details": "", loading: _vm.loading },
                        on: {
                          change: function($event) {
                            return _vm.updateCommunications("PUSH")
                          }
                        },
                        model: {
                          value: _vm.pushSettings.checkUpReminder,
                          callback: function($$v) {
                            _vm.$set(_vm.pushSettings, "checkUpReminder", $$v)
                          },
                          expression: "pushSettings.checkUpReminder"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "", "mt-2": "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "" } },
                [
                  _c("v-flex", { attrs: { xs6: "", sm8: "" } }, [
                    _c("p", { staticClass: "starling-text text--disabled" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "app.profile.communication.progress.goal_reminder"
                          )
                        )
                      )
                    ])
                  ]),
                  _c(
                    "v-flex",
                    { attrs: { xs3: "", sm2: "" } },
                    [
                      _c("v-switch", {
                        staticClass: "ma-0 justify-center",
                        attrs: { "hide-details": "", loading: _vm.loading },
                        on: {
                          change: function($event) {
                            return _vm.handleGoalReminderSwitch("EMAIL")
                          }
                        },
                        model: {
                          value: _vm.emailSettings.goalReminder,
                          callback: function($$v) {
                            _vm.$set(_vm.emailSettings, "goalReminder", $$v)
                          },
                          expression: "emailSettings.goalReminder"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs3: "", sm2: "" } },
                    [
                      _c("v-switch", {
                        staticClass: "ma-0 justify-center",
                        attrs: { "hide-details": "", loading: _vm.loading },
                        on: {
                          change: function($event) {
                            return _vm.handleGoalReminderSwitch("PUSH")
                          }
                        },
                        model: {
                          value: _vm.pushSettings.goalReminder,
                          callback: function($$v) {
                            _vm.$set(_vm.pushSettings, "goalReminder", $$v)
                          },
                          expression: "pushSettings.goalReminder"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "", "mt-2": "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "" } },
                [
                  _c("v-flex", { attrs: { xs6: "", sm8: "" } }, [
                    _c("p", { staticClass: "starling-text text--disabled" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "app.profile.communication.progress.webinar_reminder"
                          )
                        )
                      )
                    ])
                  ]),
                  _c(
                    "v-flex",
                    { attrs: { xs3: "", sm2: "" } },
                    [
                      _c("v-switch", {
                        staticClass: "ma-0 justify-center",
                        attrs: { "hide-details": "", loading: _vm.loading },
                        on: {
                          change: function($event) {
                            return _vm.updateCommunications("EMAIL")
                          }
                        },
                        model: {
                          value: _vm.emailSettings.webinarReminder,
                          callback: function($$v) {
                            _vm.$set(_vm.emailSettings, "webinarReminder", $$v)
                          },
                          expression: "emailSettings.webinarReminder"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs3: "", sm2: "" } },
                    [
                      _c("v-switch", {
                        staticClass: "ma-0 justify-center",
                        attrs: { "hide-details": "", loading: _vm.loading },
                        on: {
                          change: function($event) {
                            return _vm.updateCommunications("PUSH")
                          }
                        },
                        model: {
                          value: _vm.pushSettings.webinarReminder,
                          callback: function($$v) {
                            _vm.$set(_vm.pushSettings, "webinarReminder", $$v)
                          },
                          expression: "pushSettings.webinarReminder"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "", "align-center": "", "mt-3": "" } },
        [
          _c("v-flex", { attrs: { xs12: "" } }, [
            _c("p", { staticClass: "starling-body" }, [
              _vm._v(
                _vm._s(_vm.$t("app.profile.communication.completion.title"))
              )
            ])
          ]),
          _c(
            "v-flex",
            { attrs: { xs12: "", "mt-2": "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "" } },
                [
                  _c("v-flex", { attrs: { xs6: "", sm8: "" } }, [
                    _c("p", { staticClass: "starling-text text--disabled" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("app.profile.communication.completion.session")
                        )
                      )
                    ])
                  ]),
                  _c(
                    "v-flex",
                    { attrs: { xs3: "", sm2: "" } },
                    [
                      _c("v-switch", {
                        staticClass: "ma-0 justify-center",
                        attrs: { "hide-details": "", loading: _vm.loading },
                        on: {
                          change: function($event) {
                            return _vm.updateCommunications("EMAIL")
                          }
                        },
                        model: {
                          value: _vm.emailSettings.eventCompletion,
                          callback: function($$v) {
                            _vm.$set(_vm.emailSettings, "eventCompletion", $$v)
                          },
                          expression: "emailSettings.eventCompletion"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs3: "", sm2: "" } },
                    [
                      _c("v-switch", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false"
                          }
                        ],
                        staticClass: "ma-0 justify-center",
                        attrs: { "hide-details": "", loading: _vm.loading },
                        on: {
                          change: function($event) {
                            return _vm.updateCommunications("PUSH")
                          }
                        },
                        model: {
                          value: _vm.pushSettings.eventCompletion,
                          callback: function($$v) {
                            _vm.$set(_vm.pushSettings, "eventCompletion", $$v)
                          },
                          expression: "pushSettings.eventCompletion"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "", "mt-2": "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "" } },
                [
                  _c("v-flex", { attrs: { xs6: "", sm8: "" } }, [
                    _c("p", { staticClass: "starling-text text--disabled" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "app.profile.communication.completion.exercise"
                          )
                        )
                      )
                    ])
                  ]),
                  _c(
                    "v-flex",
                    { attrs: { xs3: "", sm2: "" } },
                    [
                      _c("v-switch", {
                        staticClass: "ma-0 justify-center",
                        attrs: { "hide-details": "", loading: _vm.loading },
                        on: {
                          change: function($event) {
                            return _vm.updateCommunications("EMAIL")
                          }
                        },
                        model: {
                          value: _vm.emailSettings.exerciseCompletion,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.emailSettings,
                              "exerciseCompletion",
                              $$v
                            )
                          },
                          expression: "emailSettings.exerciseCompletion"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs3: "", sm2: "" } },
                    [
                      _c("v-switch", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false"
                          }
                        ],
                        staticClass: "ma-0 justify-center",
                        attrs: { "hide-details": "", loading: _vm.loading },
                        on: {
                          change: function($event) {
                            return _vm.updateCommunications("PUSH")
                          }
                        },
                        model: {
                          value: _vm.pushSettings.exerciseCompletion,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.pushSettings,
                              "exerciseCompletion",
                              $$v
                            )
                          },
                          expression: "pushSettings.exerciseCompletion"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "", "align-center": "", "mt-3": "" } },
        [
          _c("v-flex", { attrs: { xs12: "" } }, [
            false
              ? _c("p", { staticClass: "starling-body" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("app.profile.communication.product_updates.title")
                    )
                  )
                ])
              : _vm._e()
          ]),
          _c(
            "v-flex",
            { attrs: { xs12: "", "mt-2": "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "" } },
                [
                  _c("v-flex", { attrs: { xs6: "", sm8: "" } }, [
                    _c("p", { staticClass: "starling-text text--disabled" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "app.profile.communication.product_updates.updates"
                          )
                        )
                      )
                    ])
                  ]),
                  _c(
                    "v-flex",
                    { attrs: { xs3: "", sm2: "" } },
                    [
                      _c("v-switch", {
                        staticClass: "ma-0 justify-center",
                        attrs: { "hide-details": "", loading: _vm.loading },
                        on: {
                          change: function($event) {
                            return _vm.updateCommunications("EMAIL")
                          }
                        },
                        model: {
                          value: _vm.emailSettings.newsletter,
                          callback: function($$v) {
                            _vm.$set(_vm.emailSettings, "newsletter", $$v)
                          },
                          expression: "emailSettings.newsletter"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs3: "", sm2: "" } },
                    [
                      _c("v-switch", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false"
                          }
                        ],
                        staticClass: "ma-0 justify-center",
                        attrs: { "hide-details": "", loading: _vm.loading },
                        on: {
                          change: function($event) {
                            return _vm.updateCommunications("PUSH")
                          }
                        },
                        model: {
                          value: _vm.pushSettings.newsletter,
                          callback: function($$v) {
                            _vm.$set(_vm.pushSettings, "newsletter", $$v)
                          },
                          expression: "pushSettings.newsletter"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("custom-confirmation-modal", {
        attrs: {
          display: _vm.showGoalReminderModal,
          title: _vm.$t("app.profile.communication.goal_reminder_modal.title"),
          description: _vm.$t(
            "app.profile.communication.goal_reminder_modal.description"
          ),
          primaryConfirmLabel: _vm.$t("common.actions.turn_off"),
          secondaryCancelLabel: _vm.$t("common.actions.cancel")
        },
        on: {
          "cancel-event": _vm.closeGoalReminderModal,
          "confirm-event": _vm.saveGoalReminder
        }
      }),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "390px", "content-class": "radius-15" },
          model: {
            value: _vm.showPushWarning,
            callback: function($$v) {
              _vm.showPushWarning = $$v
            },
            expression: "showPushWarning"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-2", attrs: { flat: "" } },
            [
              _c(
                "v-btn",
                {
                  staticStyle: {
                    "margin-right": "-12px",
                    "margin-top": "25px"
                  },
                  attrs: {
                    absolute: "",
                    light: "",
                    fab: "",
                    top: "",
                    right: "",
                    flat: "",
                    small: ""
                  },
                  on: {
                    click: function($event) {
                      _vm.showPushWarning = false
                    }
                  }
                },
                [_c("v-icon", [_vm._v("close")])],
                1
              ),
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "", "gap-xs-2": "" } },
                [
                  _c("v-flex", { attrs: { xs12: "" } }, [
                    _c("h3", { staticClass: "starling-h3" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "app.profile.communication.warning_push_disabled.title"
                          )
                        )
                      )
                    ])
                  ]),
                  _c("v-flex", { attrs: { xs12: "" } }, [
                    _c("p", { staticClass: "starling-body" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "app.profile.communication.warning_push_disabled.description"
                          )
                        )
                      )
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }