<template>
<!-- eslint-disable-next-line vue/no-mutating-props -->
<v-dialog v-model="display" persistent max-width="600px" content-class="radius-15">
  <v-card flat class="pa-2">
    <v-card-title v-if="title">
      <h1 class="headline primary--text">{{ title }}</h1>
    </v-card-title>
    <v-card-text class="pa-0">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-container fluid>
          <v-layout wrap>
            <v-flex xs12>
              <v-text-field
                v-model="currentValue"
                persistent-hint
                type="text"
                :label="label"
                :rules="rules"
                :readonly="provider !== undefined"
                :hint="hint">
              </v-text-field>
            </v-flex>
            <v-flex xs12 align-center justify-center v-if="provider!==undefined">
              <v-btn id="btn-input-provider"
                class="primary--text font-weigth-bold"
                flat block large
                @click="provide">
                <v-icon style="width:16px;" class="mr-3">{{ provider.icon }}</v-icon>
                {{provider.title}}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-layout row align-center justify-space-around gap-xs-3>
        <v-flex shrink>
          <v-btn flat round @click="onClose">
            {{$t('common.actions.cancel')}}
          </v-btn>
        </v-flex>
        <v-flex shrink>
          <primary-button :disabled="!valid" @click="onSave">
            {{$t('common.actions.save')}}
          </primary-button>
        </v-flex>
      </v-layout>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
import PrimaryButton from '@/views/components/button/primary-button';
export default {
  name: 'profile-text-field-modal',
  components: { PrimaryButton },
  props: {
    display: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      type: String,
      required: false,
      default: null,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    hint: {
      type: String,
      required: false,
      default: null,
    },
    rules: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    provider: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      currentValue: '',
      valid: true,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.currentValue = newValue;
      },
    },
  },
  methods: {
    onClose() {
      this.$emit('close');
      this.currentValue = this.value;
    },
    onSave() {
      if (this.$refs.form.validate()) {
        this.$emit('save', this.currentValue);
      }
    },
    provide() {
      this.provider.apply().then(
        res => {
          this.currentValue = res;
        },
      );
    },
  },
};
</script>
