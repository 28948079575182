var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        persistent: "",
        "max-width": "600px",
        "content-class": "radius-15"
      },
      model: {
        value: _vm.display,
        callback: function($$v) {
          _vm.display = $$v
        },
        expression: "display"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "pa-2", attrs: { flat: "" } },
        [
          _vm.title
            ? _c("v-card-title", [
                _c("h1", { staticClass: "headline primary--text" }, [
                  _vm._v(_vm._s(_vm.title))
                ])
              ])
            : _vm._e(),
          _c(
            "v-card-text",
            { staticClass: "pa-0" },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { "lazy-validation": "" },
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _vm.autocomplete
                                ? _c("v-autocomplete", {
                                    attrs: {
                                      clearable: "",
                                      items: _vm.items,
                                      label: _vm.label,
                                      "no-data-text": _vm.$t(
                                        "app.profile.misc.no_match"
                                      ),
                                      rules: _vm.rules
                                    },
                                    model: {
                                      value: _vm.currentValue,
                                      callback: function($$v) {
                                        _vm.currentValue = $$v
                                      },
                                      expression: "currentValue"
                                    }
                                  })
                                : _c("v-select", {
                                    attrs: {
                                      label: _vm.label,
                                      rules: _vm.rules,
                                      items: _vm.items,
                                      type: ""
                                    },
                                    model: {
                                      value: _vm.currentValue,
                                      callback: function($$v) {
                                        _vm.currentValue = $$v
                                      },
                                      expression: "currentValue"
                                    }
                                  })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c(
                "v-layout",
                {
                  attrs: {
                    row: "",
                    "align-center": "",
                    "justify-space-around": "",
                    "gap-xs-3": ""
                  }
                },
                [
                  _c(
                    "v-flex",
                    { attrs: { shrink: "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { flat: "", round: "" },
                          on: { click: _vm.onClose }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.actions.cancel")) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { shrink: "" } },
                    [
                      _c(
                        "primary-button",
                        {
                          attrs: { disabled: !_vm.valid },
                          on: { click: _vm.onSave }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.actions.save")) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }